import React from 'react'
import CustomTextField from './CustomTextField'
import CustomSwitchField from './CustomSwitchField'
import CustomButton from './CustomButton'
import CustomMenuField from './CustomMenuField'
import BaseField from './BaseField'
import CustomUnknown from './CustomUnknown'
import CustomTable from './CustomTable'
import CustomRichText from './CustomRichText/CustomRichText'
import { CustomDate, CustomDateTime } from '~shared/components/atoms/CustomDatePicker/CustomPickers'
import CustomTimeStamp from '~shared/components/atoms/CustomDatePicker/CustomTimeStamp'
import CustomMultipleMenu from './CustomMultipleMenu'
import CustomReference from './CustomReference'
import CustomDivider from './CustomDivider'
import CustomColor from './CustomColor'
import CustomJSON from './CustomJSON'
import CustomMedia from './CustomMedia/CustomMedia'
import CustomUserMedia from './CustomMedia/CustomUserMedia'
import Icon from '@material-ui/core/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import CustomNumber from './CustomNumber'
import CustomVReference from './CustomVReference'
import SegmentationQuery from './SegmentationQuery'
import SurveyQuestionEnumOptions from './SurveyQuestionEnumOptions'
import ContentElements from './ContentElements'
import CustomScript from './CustomScript'
import CustomRadioField from './CustomRadioField'
import CustomCheckBoxField from './CustomCheckBoxField'
import JsonTable from './JsonTable'
import AutoTestJson from '~components/atom/FieldFactory/AutoTestJson/AutoTestJson'
import JobProgressJson from '~components/atom/FieldFactory/JobProgressJson/JobProgressJson'
import AdminRole from '~components/atom/FieldFactory/AdminRole/AdminRole'
import CustomButtonCopyPaste from '~components/atom/FieldFactory/CustomButtonCopyPaste'

class FieldFactory {
  static getFieldByType(item) {
    let CustomSelection
    let icon
    const { settings: { variant } = {}, type } = item

    switch (type) {
      case 'text':
        icon = 'title'
        switch (variant) {
          case 'script':
            CustomSelection = CustomScript
            break
          default:
            CustomSelection = CustomTextField
        }
        break
      case 'email':
        icon = 'alternate_email'
        CustomSelection = CustomTextField
        break
      case 'password':
        icon = 'lock'
        CustomSelection = CustomTextField
        break
      case 'integer':
        CustomSelection = CustomNumber
        break
      case 'float':
        CustomSelection = CustomNumber
        break
      case 'slug':
        CustomSelection = CustomTextField
        break
      case 'date':
        icon = 'date_range'
        CustomSelection = CustomDate
        break
      case 'datetime':
        icon = 'date_range'
        CustomSelection = CustomDateTime
        break
      case 'timestamp':
        icon = 'schedule'
        CustomSelection = CustomTimeStamp
        break
      case 'boolean':
        CustomSelection = CustomSwitchField
        break
      case 'button':
        switch (variant) {
          case 'copyPaste':
            CustomSelection = CustomButtonCopyPaste
            break
          default:
            CustomSelection = CustomButton
        }
        break
      case 'enum':
        switch (variant) {
          case 'radio':
            CustomSelection = CustomRadioField
            break
          default:
            CustomSelection = CustomMenuField
        }
        break
      case 'multiple':
        switch (variant) {
          case 'checkbox':
            CustomSelection = CustomCheckBoxField
            break
          default:
            CustomSelection = CustomMultipleMenu
        }
        break
      case 'table':
        CustomSelection = CustomTable
        break
      case 'richtext':
        CustomSelection = CustomRichText
        break
      case 'reference':
        CustomSelection = CustomReference
        break
      case 'vreference':
        CustomSelection = CustomVReference
        break
      case 'divider':
        CustomSelection = CustomDivider
        break
      case 'json':
        switch (variant) {
          case 'segmentation':
            CustomSelection = SegmentationQuery
            break
          case 'surveyQuestionEnumOptions':
            CustomSelection = SurveyQuestionEnumOptions
            break
          case 'contentElements':
            CustomSelection = ContentElements
            break
          case 'table':
            CustomSelection = JsonTable
            break
          case 'autoTest':
            CustomSelection = AutoTestJson
            break
          case 'adminRolePermissions':
            CustomSelection = AdminRole
            break
          case 'jobProgress':
            CustomSelection = JobProgressJson
            break
          default:
            CustomSelection = CustomJSON
        }
        break
      case 'media':
        CustomSelection = CustomMedia
        break
      case 'usermedia':
        CustomSelection = CustomUserMedia
        break
      case 'color':
        CustomSelection = CustomColor
        break
      default:
        CustomSelection = CustomUnknown
        break
    }

    const InputProps = item.InputProps || {}

    if (icon) {
      const alpha = item.disabled ? 0.25 : 0.54
      InputProps.startAdornment = (
        <InputAdornment style={item.multiline ? { height: '1em' } : null} position="start">
          <Icon style={{ color: `rgba(0,0,0,${alpha})` }}>{icon}</Icon>
        </InputAdornment>
      )
    }

    const { api } = item

    return (
      <BaseField {...item} InputProps={InputProps} api={api}>
        <CustomSelection />
      </BaseField>
    )
  }
}

export default FieldFactory
